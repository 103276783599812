import axios from 'axios';
import { formDataHeader, formDataHeaderToken, jsonHeaders, jsonHeadersToken } from '../helpers';
import { config } from '../config/config';


var configFormDataHeaders = {
    headers: formDataHeader()
}

var formDataToken = {
    headers: formDataHeaderToken()
}

var configJsonHeaders = {
    headers: jsonHeaders()
}

var jsonHeadersTokenHeader = {
    headers: jsonHeadersToken()
}


export const apiService = {
    userRegister,
    userLogin,
    getMarketplaceData
};

function userRegister(params){
    return axios.post(`${config.apiUrl}/user/register`, params, formDataToken)
}

function userLogin(params){
    return axios.post(`${config.apiUrl}/user/login`,params,configJsonHeaders)
}

function getMarketplaceData(role,page){
    return axios.get(`${config.apiUrl}/user/list?role=${role}&page=${page}`)
}