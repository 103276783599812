import { useState } from 'react';
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';

const GeneratePassword = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      {/* Page start */}
      <section className="login-section">
        <Container fluid>
          <Row className="align-items-center">
            <Col md={6}>
              <div className="left-side-img">
                <img src={require('../assets/images/forgot-img.png')} alt="Left image || Brandfluence" className="w-100" />
              </div>
            </Col>
            <Col md={6}>
              <div className="right-side-form">
                <img src={require('../assets/images/back-icon.svg').default} alt="Logo || Brandfluence" className="back-btn d-block" />
                <img src={require('../assets/images/logo-icon.png')} alt="Logo || Brandfluence" className="" />
                <h1>Create New Password</h1>
                <Form className="forgot-form">
                  <Form.Group className="mb-3 position-relative" controlId="formBasicPassword">
                    <Form.Label>New Password</Form.Label>
                    <Form.Control type="password" placeholder="Enter your new password" />
                    <img src={require('../assets/images/view-eye.svg').default} alt="Logo || Brandfluence" className="eye-icon" />
                  </Form.Group>
                  <Form.Group className="mb-3 position-relative" controlId="formBasicPassword">
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control type="password" placeholder="Enter your confirm password" />
                    <img src={require('../assets/images/view-eye.svg').default} alt="Logo || Brandfluence" className="eye-icon" />
                  </Form.Group>
                  <Button variant="primary" className="submit-btn" onClick={handleShow}>
                    Update
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* success modal start */}
      <Modal show={show} onHide={handleClose} className='confirmation-outer-modal' centered>
        <Modal.Header closeButton className="border-0 close-btns">
        </Modal.Header>
        <Modal.Body>
          <div className='confirmation-details text-center'>
            <img src={require('../assets/images/check-icon.png')} alt="Logo || Brandfluence" className="" />
            <h2>Password Successfully
              Updated!</h2>
            <p>Your password has been successfully updated. You can now log in with your new credentials.</p>
          </div>
        </Modal.Body>
      </Modal>
      {/* success modal end */}
      {/* Page end */}
    </>
  );
};
export default GeneratePassword;
