import { Col, Container, Row } from "react-bootstrap";

const Footer = () => {
  return <>
    {/* footer */}
    <footer>
      <Container>
        <Row className="align-items-center">
          <Col md={3}>
            <div className="footer-logo">
              <img src={require('../assets/images/footer-logo.png')} alt="Left image || Brandfluence" className="" />
            </div>
          </Col>
          <Col md={6}>
            <div className="footer-content text-center">
              <a href="#">About</a>
              <a href="#">Brands</a>
              <a href="#">Categories</a>
              <a href="#">Contact Us</a>
            </div>
          </Col>
          <Col md={3}>
            <div className="footer-content social-media">
              <a href="#">
                <img src={require('../assets/images/fb-icon.svg').default} alt="Left image || Brandfluence" className="" />
              </a>
              <a href="#">
              <img src={require('../assets/images/ins-icon.svg').default} alt="Left image || Brandfluence" className="" />
              </a>
              <a href="#">
              <img src={require('../assets/images/you-icon.svg').default} alt="Left image || Brandfluence" className="" />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  </>;
};
export default Footer;
