import { Button, Col, Container, Form, Row } from "react-bootstrap";

const ForgotPassword = () => {
  return (
    <>
      {/* Page start */}
      <section className="login-section">
        <Container fluid>
          <Row className="align-items-center">
            <Col md={6}>
              <div className="left-side-img">
                <img src={require('../assets/images/forgot-img.png')} alt="Left image || Brandfluence" className="w-100" />
              </div>
            </Col>
            <Col md={6}>
              <div className="right-side-form">
                <img src={require('../assets/images/back-icon.svg').default} alt="Logo || Brandfluence" className="back-btn d-block" />
                <img src={require('../assets/images/logo-icon.png')} alt="Logo || Brandfluence" className="" />
                <h1>Forgot Password</h1>
                <Form className="forgot-form">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" placeholder="Email" />
                  </Form.Group>
                  <Button variant="primary" type="submit" className="submit-btn">
                    Submit
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Page end */}
    </>
  );
};
export default ForgotPassword;
