import { Button, Col, Container, Row } from "react-bootstrap";

const Home = () => {
    return (
        <>
            {/* Hero section start */}
            <section className="hero-section">
                <Container>
                    <Row className="align-items-center">
                        <Col md={12} lg={6}>
                            <div className="hero-content">
                                <h1>Real Influence for
                                    <span> Brands</span></h1>
                                <p>Our marketplace offers a wide range of features designed to make the collaboration process efficient and effective. </p>
                                <Button className="explore-btns">Explore more <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.0254 4.94141L17.0837 9.99974L12.0254 15.0581" stroke="#1037FD" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M2.91602 10H16.941" stroke="#1037FD" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                </Button>
                            </div>
                        </Col>
                        <Col md={12} lg={6}>
                            <div className="hero-img">
                                <img src={require('../assets/images/hero-img.png')} alt="Left image || Brandfluence" />
                            </div>
                        </Col>
                    </Row>
                </Container>
                <img src={require('../assets/images/scroll.png')} alt="Left image || Brandfluence" className="scroll-icon" />
            </section>
            {/* Hero section end */}

            {/* About section start */}
            <section className="about-section">
                <Container>
                    <Row className="align-items-center justify-content-between">
                        <Col md={12} lg={6}>
                            <div className="about-img">
                                <img src={require('../assets/images/creator.png')} alt="Left image || Brandfluence" className="w-100" />
                            </div>
                        </Col>
                        <Col md={12} lg={6}>
                            <div className="about-content">
                                <span>CREATORS</span>
                                <h2>How <span>BrandFluence</span><br></br>
                                    Works for <span>Creators</span>?</h2>
                                <Row>
                                    <Col md={6}>
                                        <div className="creator-box">
                                            <img src={require('../assets/images/creator-icon-1.svg').default} alt="Left image || Brandfluence" />
                                            <h4>Create Account</h4>
                                            <p>Our marketplace offers a wide range of features designed to make the collaboration process efficient and effective. </p>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="creator-box second-box">
                                            <img src={require('../assets/images/creator-icon-2.svg').default} alt="Left image || Brandfluence" />
                                            <h4>Create Account</h4>
                                            <p>Our marketplace offers a wide range of features designed to make the collaboration process efficient and effective. </p>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="creator-box second-box">
                                            <img src={require('../assets/images/creator-icon-3.svg').default} alt="Left image || Brandfluence" />
                                            <h4>Create Account</h4>
                                            <p>Our marketplace offers a wide range of features designed to make the collaboration process efficient and effective. </p>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="creator-box">
                                            <img src={require('../assets/images/creator-icon-4.svg').default} alt="Left image || Brandfluence" />
                                            <h4>Create Account</h4>
                                            <p>Our marketplace offers a wide range of features designed to make the collaboration process efficient and effective. </p>
                                        </div>
                                    </Col>
                                </Row>
                                <Button className="explore-btns second-ver">Explore more <svg width="20" height="20" className="ms-2" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.0254 4.94141L17.0837 9.99974L12.0254 15.0581" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M2.91602 10H16.941" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            {/* About section end */}

            {/* About Brand section start */}
            <section className="about-section about-brand">
                <Container>
                    <Row className="align-items-center justify-content-between">
                        <Col md={12} lg={6}>
                            <div className="about-content">
                                <span>BRAND</span>
                                <h2>How <span>BrandFluence</span><br></br>
                                    Works for <span>Brands</span>?</h2>
                                <Row>
                                    <Col md={6}>
                                        <div className="creator-box">
                                            <img src={require('../assets/images/creator-icon-1.svg').default} alt="Left image || Brandfluence" />
                                            <h4>Create Account</h4>
                                            <p>Our marketplace offers a wide range of features designed to make the collaboration process efficient and effective.  </p>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="creator-box second-box">
                                            <img src={require('../assets/images/creator-icon-2.svg').default} alt="Left image || Brandfluence" />
                                            <h4>Create Account</h4>
                                            <p>Our marketplace offers a wide range of features designed to make the collaboration process efficient and effective. </p>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="creator-box second-box">
                                            <img src={require('../assets/images/creator-icon-3.svg').default} alt="Left image || Brandfluence" />
                                            <h4>Create Account</h4>
                                            <p>Our marketplace offers a wide range of features designed to make the collaboration process efficient and effective. </p>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="creator-box">
                                            <img src={require('../assets/images/creator-icon-4.svg').default} alt="Left image || Brandfluence" />
                                            <h4>Create Account</h4>
                                            <p>Our marketplace offers a wide range of features designed to make the collaboration process efficient and effective. </p>
                                        </div>
                                    </Col>
                                </Row>
                                <Button className="explore-btns second-ver">Explore more <svg width="20" height="20" className="ms-2" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.0254 4.94141L17.0837 9.99974L12.0254 15.0581" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M2.91602 10H16.941" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                </Button>
                            </div>
                        </Col>
                        <Col md={12} lg={6}>
                            <div className="about-img">
                                <img src={require('../assets/images/brand.png')} alt="Left image || Brandfluence" className="w-100" />
                            </div>
                        </Col>

                    </Row>
                </Container>
            </section>
            {/* About Brand section end */}

            {/* Social section start */}
            <section className="social-section text-center">
                <Container>
                    <h2>Connecting brands with digital stars that takes you far.</h2>
                    <Button className="explore-btns">Explore more <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.0254 4.94141L17.0837 9.99974L12.0254 15.0581" stroke="#1037FD" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M2.91602 10H16.941" stroke="#1037FD" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    </Button>
                    <img src={require('../assets/images/facebook.png')} alt="Left image || Brandfluence" className="facebook-icon" />
                    <img src={require('../assets/images/youtube.png')} alt="Left image || Brandfluence" className="youtube-icon" />
                    <img src={require('../assets/images/instagram (1).png')} alt="Left image || Brandfluence" className="instagram-icon" />
                </Container>
            </section>
            {/* Social section end */}
        </>
    )
};
export default Home;