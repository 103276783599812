import React, { useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { apiService } from "../services/apiservices";
import Swal from "sweetalert2";

const BrandFrom = ({ setShowLoader }) => {
  const workMonthlyOptions = Array.from({ length: 11 }, (_, i) => i);
  const imageRef = useRef();
  const [showPassword, setShowPassword] = useState(false);
  const [profileImage, setProfileImage] = useState("")

  const [brandData, setBrandData] = useState({
    image: "",
    brandName: "",
    email: "",
    phoneNumber: "",
    role: "brand",
    websiteLinks: "",
    socialLinks: [""],
    tikTokShop: "no",
    workMonthly: 0,
    isWeb: true,
    affiliateGMV: "",
  });

  const [errors, setErrors] = useState({
    socialLinks: [],
  });

  const handleChangeSocialLink = (index, value) => {
    const updatedLinks = [...brandData.socialLinks];
    updatedLinks[index] = value;
    setBrandData({ ...brandData, socialLinks: updatedLinks });

    const updatedErrors = [...errors.socialLinks];
    const urlRegex = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)\/?.*$/;
    if (value && !urlRegex.test(value)) {
      updatedErrors[index] = "Please enter a valid URL.";
    } else {
      updatedErrors[index] = "";
    }
    setErrors({ ...errors, socialLinks: updatedErrors });
  };

  const addSocialLink = () => {
    setBrandData({ ...brandData, socialLinks: [...brandData.socialLinks, ""] });
    setErrors({ ...errors, socialLinks: [...errors.socialLinks, ""] });
  };

  const validateInput = (name, value) => {
    let error = "";

    switch (name) {
        case "image":
        if(!value){
          error = "Profile image is required.";
        }
        break;
      case "brandName":
        if (!value.trim()) {
          error = `${
            name === "firstName" ? "First name" : "Last name"
          } is required.`;
        } else if (!/^[A-Za-z\s]+$/.test(value)) {
          error = `${
            name === "firstName" ? "First name" : "Last name"
          } must contain only letters and spaces.`;
        } else if (value.length < 2 || value.length > 50) {
          error = `${
            name === "firstName" ? "First name" : "Last name"
          } must be between 2 and 50 characters.`;
        }
        break;
      case "email":
        if (!value.trim()) {
          error = "Email is required.";
        } else if (
          !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/.test(value)
        ) {
          error = "Invalid email format.";
        }
        break;
      case "phoneNumber":
        // if (!value.trim()) {
        //     error = "Phone number is required.";
        // } else
        if (value && !/^\d{10}$/.test(value)) {
          error = "Phone number must be 10 digits.";
        }
        break;
        case "password":
        const trimmedValue = value.trim();
        if (!trimmedValue) {
          error = "Password is required.";
        } else if (trimmedValue.length < 8) {
          error = "Password must be at least 8 characters long.";
        } else if (
          !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[`~!@#$%^&*()-_+={[}|:;"'<,>.?/]).*$/.test(
            trimmedValue
          )
        ) {
          error =
            "Password must include at least 1 letter, 1 number, and 1 special character.";
        }
        break;
      case "websiteLinks":
        const urlRegex = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)\/?.*$/;
        if (!value.trim()) {
          error = "Website links is required.";
        } else if (value && !urlRegex.test(value)) {
          error = "Please enter a valid URL.";
        }
        break;

      case "affiliateGMV":
        if (!value.trim()) {
          error = "Affiliate GMV is required.";
        }
        break;
      default:
        break;
    }

    return error;
  };
  const removeSocialLink = (index) => {
    // Remove the social link from the array at the given index
    const updatedSocialLinks = [...brandData.socialLinks];
    updatedSocialLinks.splice(index, 1); // Remove 1 item at the specified index

    // Remove the corresponding error message (if any)
    const updatedErrors = { ...errors };
    updatedErrors.socialLinks.splice(index, 1);

    // Update the state with the modified arrays
    setBrandData({ ...brandData, socialLinks: updatedSocialLinks });
    setErrors(updatedErrors);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Perform form submission logic here

    // Validate all fields
    const newErrors = {};
    Object.keys(brandData).forEach((key) => {
      newErrors[key] = validateInput(key, brandData[key]);
    });

    setErrors(newErrors);


    // Check if there are no errors
    if (Object.values(newErrors).every((error) => error === "")) {
      setShowLoader(true);

      const form = new FormData();
      form.append("image", brandData.image);
      form.append("role", brandData.role);
      form.append("brandName", brandData.brandName);
      form.append("websiteLinks",brandData.websiteLinks)
      form.append("email", brandData.email);
      form.append("phoneNumber", brandData.phoneNumber);
      form.append("password", brandData.password);
      brandData.socialLinks.map((link,index)=>{
        if(link.trim() !== ""){
          form.append("socialLinks", link)
        }
      })
      form.append(
        "isActiveTikTokAffiliate",
        brandData.tikTokShopAffiliate == "yes" ? true : false
      );
      form.append("monthlyBrandGoal", brandData.workMonthly);
      form.append("isWeb", brandData.isWeb);
      form.append("last30DaysAffiliateGMV", brandData.affiliateGMV);

      const params = {
        role: brandData.role,
        brandName: brandData.brandName,
        websiteLinks: brandData.websiteLinks,
        email: brandData.email,
        phoneNumber: brandData.phoneNumber,
        socialLinks: brandData.socialLinks.filter((link) => link.trim() !== ""),
        isActiveTikTokAffiliate: brandData.tikTokShop == "yes" ? true : false,
        monthlyBrandGoal: brandData.workMonthly,
        last30DaysAffiliateGMV: brandData.affiliateGMV,
      };
      try {
        const response = await apiService.userRegister(form);
        // const response = {status : 201}
        if (response.status == 201) {
          // Show success message
          setBrandData({
            image: "",
            brandName: "",
            email: "",
            phoneNumber: "",
            role: "brand",
            websiteLinks: "",
            socialLinks: [""],
            tikTokShop: "no",
            workMonthly: 0,
            isWeb: true,
            affiliateGMV: "",
          });
          setProfileImage("")
          imageRef.current.value = "";
          setShowLoader(false);
          Swal.fire({
            icon: "success",
            title: "Registration Successful",
            text: response?.message || "You have successfully registered!",
          });
        }
      } catch (error) {
        // Show error message for any network or unexpected error
        setShowLoader(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text:
            error?.response?.data?.message ||
            "Something went wrong, please try again.",
        });
      }
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setBrandData((prev) => ({ ...prev, [name]: value }));
    const error = validateInput(name, value);
    setErrors({ ...errors, [name]: error });
  };

  const handleFileChange = (event) => {
    const { name, files } = event.target;
    const file = files[0];

    const imageUrl = URL.createObjectURL(file)
    console.log(imageUrl)
    setProfileImage(imageUrl)

    setBrandData((prev) => ({ ...prev, [name]: file }));

    const error = validateInput(name, file);

    setErrors((prev) => ({ ...prev, [name]: error }));
  };

  return (
    <Form>
        <Row>
        <Col md={6}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Upload Your Profile</Form.Label>
              <div className="upload-img">
                <img src={profileImage? profileImage : require("../assets/images/upload-icon.png")} />
                <Form.Control
                  type="file"
                  placeholder="Upload Image"
                  name="image"
                  onChange={handleFileChange}
                  ref={imageRef}
                />
              </div>
              {errors.image && (
                <span className="text-danger">{errors.image}</span>
              )}
            </Form.Group>
        </Col>
      </Row>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Brand Name</Form.Label>
        <Form.Control
          type="email"
          placeholder="Brand Name"
          name="brandName"
          value={brandData.brandName}
          onChange={handleInputChange}
        />
        {errors.brandName && (
          <span className="text-danger">{errors.brandName}</span>
        )}
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="email"
          placeholder="Email"
          name="email"
          value={brandData.email}
          onChange={handleInputChange}
        />
        {errors.email && <span className="text-danger">{errors.email}</span>}
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Phone Number</Form.Label>
        <Form.Control
          type="number"
          placeholder="Phone Number"
          name="phoneNumber"
          value={brandData.phoneNumber}
          onChange={handleInputChange}
        />
        {errors.phoneNumber && (
          <span className="text-danger">{errors.phoneNumber}</span>
        )}
      </Form.Group>
      <Form.Group
          className="mb-3 position-relative"
          controlId="formBasicPassword"
        >
          <Form.Label>Password</Form.Label>
          <Form.Control
            type={showPassword ? "text" : "password"}
            placeholder="Enter your password"
            name="password"
            value={brandData.password}
            onChange={handleInputChange}
          />
          {errors.password && (
            <span className="text-danger">{errors.password}</span>
          )}
          <img
            onClick={() => {
              setShowPassword((prev) => !prev);
            }}
            src={
              showPassword
                ? require("../assets/images/view-eye.svg").default
                : require("../assets/images/eye-open.svg").default
            }
            alt="Logo || Brandfluence"
            className="eye-icon"
          />
        </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Website Links</Form.Label>
        <Form.Control
          type="text"
          placeholder="Website links"
          name="websiteLinks"
          value={brandData.websiteLinks}
          onChange={handleInputChange}
        />
        {errors.websiteLinks && (
          <span className="text-danger">{errors.websiteLinks}</span>
        )}
      </Form.Group>
      <Form.Group className="mb-1" controlId="formBasicEmail">
        <Form.Label>Social Links</Form.Label>
        {brandData.socialLinks.map((link, index) => (
          <div key={index} className="mb-2 trash-area">
            <Form.Control
              type="text"
              placeholder={`Social Link ${index + 1}`}
              value={link}
              onChange={(e) => handleChangeSocialLink(index, e.target.value)}
            />
            {index != 0 ? (
              <i
                className="fa fa-trash"
                aria-hidden="true"
                onClick={() => removeSocialLink(index)}
              ></i>
            ) : (
              ""
            )}
            {errors.socialLinks[index] && (
              <span className="text-danger">{errors.socialLinks[index]}</span>
            )}
          </div>
        ))}
        <Button variant="link" onClick={addSocialLink} className="add-btn">
          + Add More
        </Button>
      </Form.Group>
      <Form.Group className="mb-3 position-relative" controlId="formBasicEmail">
        <Form.Label>Is your brands currently on Tik Tok Shop?</Form.Label>
        <Form.Select
          name="tikTokShop"
          value={brandData.tikTokShop}
          onChange={handleInputChange}
        >
          <option value={"yes"}>Yes</option>
          <option value={"no"}>No</option>
        </Form.Select>
        <img
          src={require("../assets/images/dropdown.svg").default}
          alt="Logo || Brandfluence"
          className="eye-icon"
        />
      </Form.Group>
      <Form.Group className="mb-3 position-relative" controlId="formBasicEmail">
        <Form.Label>
          How many creators are you looking to work with monthly?
        </Form.Label>
        <Form.Select
          name="workMonthly"
          value={brandData.workMonthly}
          onChange={handleInputChange}
        >
          {workMonthlyOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </Form.Select>
        <img
          src={require("../assets/images/dropdown.svg").default}
          alt="Logo || Brandfluence"
          className="eye-icon"
        />
      </Form.Group>
      <Form.Group className="mb-3 position-relative" controlId="formBasicEmail">
        <Form.Label>
          How much affiliate GMV are you looking to generate per month?
        </Form.Label>
        <Form.Control
          type="number"
          placeholder={`1000`}
          name="affiliateGMV"
          value={brandData.affiliateGMV}
          onChange={handleInputChange}
        />
        {errors.affiliateGMV && (
          <span className="text-danger">{errors.affiliateGMV}</span>
        )}
        {/* <Form.Select name="affiliateGMV" value={brandData.affiliateGMV} onChange={handleInputChange}>
                    {affiliateGMVOptions.map((option) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </Form.Select>
                <img src={require('../assets/images/dropdown.svg').default} alt="Logo || Brandfluence" className="eye-icon" /> */}
      </Form.Group>
      <Button
        variant="primary"
        type="submit"
        className="submit-btn"
        onClick={handleSubmit}
      >
        Submit
      </Button>
    </Form>
  );
};

export default BrandFrom;
