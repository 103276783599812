import { Button, Col, Container, Form, Row } from "react-bootstrap";
import RegisterForm from "./RegisterForm";
import CreatorForm from "./CreatorForm";
import { useState } from "react";
import Loader from "../common/Loader";

const Creator = () => {
    const [showLoader, setShowLoader] = useState(false);
    return (
        <>
            {/* Creator Hero section start */}
            <Loader showLoader={showLoader}/>
            <section className="creator-bg">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={10}>
                            <div className="creator-cnt">
                                <h1>Welcome to Brandfluence:</h1>
                                <h3>Where Creators Thrive</h3>
                            </div>
                        </Col>
                    </Row>
                    <img src={require('../assets/images/asset-2.svg').default} alt="Left image || Brandfluence" className="assets-2" />
                    <img src={require('../assets/images/asset-1.svg').default} alt="Left image || Brandfluence" className="assets-1" />
                </Container>
            </section>
            {/* Creator Hero section end */}

            {/* Community section start */}
            <section className="community-section">
                <Container>
                    <h2> <span className="join-text">Join a community</span> of creators making <br></br>
                        <span className="join-text-under">real impact</span> —and <span className="join-text-under">real money</span>. Let’s grow together</h2>
                    <Row>
                        <Col md={6}>
                            <div className="under-cmny">
                                <img src={require('../assets/images/comunity.png')} alt="Left image || Brandfluence" className="" />
                                <h3>Get paid <span>for your</span>  creativity</h3>
                                <p>Our marketplace offers a wide range of features designed to make the collaboration process efficient and effective. </p>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="under-cmny">
                                <img src={require('../assets/images/creator-2.png')} alt="Left image || Brandfluence" className="" />
                                <h3>Earn <span>big</span></h3>
                                <p>Ourcreators are earning 4-5 figures every month by creating engaging, authentic content for brands.  </p>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="under-cmny">
                                <img src={require('../assets/images/creator-3.png')} alt="Left image || Brandfluence" className="" />
                                <h3>Drive results <span>for</span> brands</h3>
                                <p>Brandfluence creators are helping brands achieve six-figure growth while building their own success.  </p>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="under-cmny">
                                <img src={require('../assets/images/creator-4.png')} alt="Left image || Brandfluence" className="" />
                                <h3>Maximize <span>your</span> potential</h3>
                                <p>We offer tools, guidance, and strategies to maximize your time and ensure income from your TikTok content. </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            {/* Community section end */}

            {/* Performace section start */}
            <section className="community-section performance-section">
                <Container>
                    <h2 className="text-center"> <span className="join-text">Creators</span> performance with <span className="join-text">BrandFluence</span> <br></br>
                        after 28 days</h2>
                    <Row>
                        <Col md={4}>
                            <div className="performace-inner">
                                <p>GMV</p>
                                <h4>$99.8K</h4>
                                <span>+919%</span>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="performace-inner">
                                <p>Items Sold</p>
                                <h4>$6.3K</h4>
                                <span>+2K%</span>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="performace-inner">
                                <p>Est. commission</p>
                                <h4>$28.8K</h4>
                                <span>+787%</span>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="performace-inner">
                                <p>Commission base</p>
                                <h4>$104.3K</h4>
                                <span>+905%</span>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="performace-inner">
                                <p>Product views</p>
                                <h4>8.0M</h4>
                                <span>+4K%</span>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="performace-inner">
                                <p>Products clicks</p>
                                <h4>30.2K</h4>
                                <span>+729%</span>
                            </div>
                        </Col>
                    </Row>
                    <h5>Key Data</h5>
                    <Row>
                        <Col md={4}>
                            <div className="performace-inner">
                                <p>GMV</p>
                                <h4>$22.3K</h4>
                                <span>+23.60%</span>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="performace-inner">
                                <p>Items Sold</p>
                                <h4>$1.5K</h4>
                                <span>+12.05%</span>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="performace-inner">
                                <p>Est. commission</p>
                                <h4>$6.5K</h4>
                                <span>+17.55%</span>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="performace-inner">
                                <p>Commission base</p>
                                <h4>$23.4K</h4>
                                <span>+15.67%</span>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="performace-inner">
                                <p>Product views</p>
                                <h4>862.7K</h4>
                                <span className="red-error">-19.86%</span>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="performace-inner">
                                <p>Products clicks</p>
                                <h4>6.8K</h4>
                                <span>+12.49%</span>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            {/* Performace section end */}

            {/* Register section start */}
            <section className="login-section creator-register-section creator-registor">
                <Container fluid>
                    <Row className="align-items-center">
                        <Col md={6}>
                            <div className="left-side-img register-left-side">
                                <img src={require('../assets/images/creator-inner.png')} alt="Left image || Brandfluence" className="w-100" />
                            </div>
                        </Col>
                        <Col md={6}>
                            <RegisterForm roleType={"creator"} setShowLoader={setShowLoader} />
                        </Col>
                    </Row>
                </Container>
            </section>
            {/* Register section start */}
        </>
    )
};
export default Creator;