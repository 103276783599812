import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import Select from "react-select";
import { apiService } from "../services/apiservices";
import MarketPlaceChnageTab from "./MarketPlaceChangeTab";
import MarketPlaceDataCard from "./MarketPlaceDataCard";
import MarketPlaceDataDetailModal from "./MarketPlaceDataDetailModal";
import MarketPlacePagination from "./MarketPlacePagination";
import Pagination from "react-js-pagination";

const options = [
  { value: "Diamond", label: "Diamond" },
  { value: "Platinum", label: "Platinum" },
  { value: "Gold", label: "Gold" },
  { value: "Silver", label: "Silver" },
];

const categories = [
  { value: "All", label: "All" },
  { value: "Home Supplies", label: "Home Supplies" },
  { value: "Kitchenware", label: "Kitchenware" },
  { value: "Household Appliances", label: "Household Appliances" },
  { value: "Womenswears", label: "Womenswears" },
  { value: "Menswear", label: "Menswear" },
  { value: "Beauty & Personal Care", label: "Beauty & Personal Care" },
  { value: "Electronics", label: "Electronics" },
  { value: "Pet Supplies", label: "Pet Supplies" },
  { value: "Sports & Outdoors", label: "Sports & Outdoors" },
  { value: "Toys & Hobbies", label: "Toys & Hobbies" },
  { value: "Furniture", label: "Furniture" },
  { value: "Health", label: "Health" },
  { value: "Automotive", label: "Automotive" },
  { value: "Fashion", label: "Fashion" },
  { value: "Bags", label: "Bags" },
  { value: "Jewelry", label: "Jewelry" },
  { value: "Food & Beverage", label: "Food & Beverage" },
];

const Marketplace = () => {
  const [selectedTab, setSelectedTab] = useState("brand");
  const [selectedCardData, setSelectedCardData] = useState();
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1)
  const [totalUser, setTotalUser] = useState(0)

  const [marketplaceData, setMarketplaceData] = useState();

  useEffect(() => {
    getData();
  }, [selectedTab]);

  const getData = async () => {
    try {
      const response = await apiService.getMarketplaceData(selectedTab, 1);
      setMarketplaceData(response.data.list);
      setTotalPage(response.data.totalPages);
      setTotalUser(response.data.totalUsers)
    } catch (error) {
      console.log(error);
    }
  };

  const handleTabChange = async (tab) =>{
    setMarketplaceData()
    try{
        const response = await apiService.getMarketplaceData(tab, 1);
      setMarketplaceData(response.data.list);
      setTotalPage(response.data.totalPages);
      setTotalUser(response.data.totalUsers)
      setSelectedTab(tab)
      setCurrentPage(1)
      console.log(tab)
    }catch(error){
        console.log(error);
    }
  }

  const handlePageChange = async (page) => {
    setMarketplaceData()
    try{
        const response = await apiService.getMarketplaceData(selectedTab, page);
      setMarketplaceData(response.data.list);
      setTotalPage(response.data.totalPages);
      setTotalUser(response.data.totalUsers)
      setCurrentPage(page)
    }catch(error){
        console.log(error);
    }
  }

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      {/* Marketplace section start */}
      <section className="marketplace-section">
        <Container>
          <h2>Marketplace</h2>
        </Container>
      </section>
      <section className="filter-box">
        <Container>
          <div className="filter-box-inner">
            <Tabs
              defaultActiveKey="brand"
              id="uncontrolled-tab-example"
              className="mb-3"
              onSelect={(key) => handleTabChange(key)}
            >
              <Tab eventKey="brand" title="brand">
                <MarketPlaceChnageTab
                  tabType="brand"
                  options={options}
                  categories={categories}
                />
              </Tab>
              <Tab eventKey="creator" title="creator">
                <MarketPlaceChnageTab
                  tabType="creator"
                  options={options}
                  categories={categories}
                />
              </Tab>
            </Tabs>
          </div>
        </Container>
      </section>
      <section className="marketplace-listing">
        <Container>
          <Row>
            {marketplaceData &&
              marketplaceData.map((data) => {
                return (
                  <MarketPlaceDataCard
                    setSelectedCardData={setSelectedCardData}
                    handleShow={handleShow}
                    key={data._id}
                    data={data}
                  />
                );
              })}
          </Row>
        </Container>
      </section>
      {/* Marketplace section end */}

      {/* modal */}

      {selectedCardData && show ? (
        <MarketPlaceDataDetailModal
          data={selectedCardData}
          show={show}
          handleClose={handleClose}
        />
      ) : (
        <></>
      )}
      <div className="d-flex justify-content-center mb-4">
        {/* <MarketPlacePagination totalPage={totalPage} /> */}
        <Pagination
          activePage={currentPage}
          itemsCountPerPage={9}
          totalItemsCount={totalUser}
          pageRangeDisplayed={totalPage}
          onChange={(e) => handlePageChange(e)}
          hideDisabled={true}
          prevPageText={"Prev"}
          nextPageText={"Next"}
        />
      </div>
    </>
  );
};
export default Marketplace;
