import { Button, Col, Container, Form, Row, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import Loader from "../common/Loader";
import LoginForm from "./LoginForm";
import { useState } from "react";

const Login = () => {
  const [showLoader, setShowLoader] = useState(false);
  return (
    <>
      {/* Login page start */}
      <Loader showLoader={showLoader}/>
      <section className="login-section">
        <Container fluid>
          <Row className="align-items-center">
            <Col md={6}>
              <div className="left-side-img">
                <img src={require('../assets/images/left-img.png')} alt="Left image || Brandfluence" className="w-100" />
                {/* <img src={require('../assets/images/brand-img.png')} alt="Left image || Brandfluence" className="w-100" /> */}
              </div>
            </Col>
            <Col md={6}>
              <div className="right-side-form">
                <img src={require('../assets/images/logo-icon.png')} alt="Logo || Brandfluence" className="" />
                <h1>Login</h1>
                <Tabs
                  defaultActiveKey="creator"
                  id="uncontrolled-tab-example"
                  className=""
                >
                  <Tab eventKey="creator" title="As Creator">
                    <LoginForm roleType="creator" setShowLoader={setShowLoader} />
                  </Tab>
                  <Tab eventKey="brand" title="As Brand">
                    <LoginForm roleType="brand" setShowLoader={setShowLoader} />
                  </Tab>
                </Tabs>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Login page end */}
    </>
  );
};
export default Login;
