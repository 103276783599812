import { Button, Col, Container, Form, Row, Tab, Tabs } from "react-bootstrap";
import CreatorForm from "./CreatorForm";
import BrandFrom from "./BrandFrom";
import Loader from "../common/Loader";
import { useState } from "react";

const LaunchForm = () => {
  const [showLoader, setShowLoader] = useState(false);

  return (
    <>
      <Loader showLoader={showLoader}/>

      {/* Launch page start */}
      <section className="login-section launch-section">
        <Container>
          <Row>
            <Col md={5} lg={6}>
              <div className="coming-soon">
                <img src={require('../assets/images/main-logo.svg').default} alt="Logo || Brandfluence" className="" />
                <span>Coming soon</span>
                <h1>Get Notified</h1>
                <h2>When we Launch</h2>
              </div>
            </Col>
            <Col md={7} lg={6}>
              <div className="right-side-form under-launch-form">

                <h1>May I learn more about you?</h1>
                <Tabs
                  defaultActiveKey="creator"
                  id="uncontrolled-tab-example"
                  className=""
                >
                  <Tab eventKey="creator" title="As Creator">
                    {/* <Form>
                      <Row>
                        <Col md={6}>
                          <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>First name</Form.Label>
                            <Form.Control type="text" placeholder="First Name" />
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Last name</Form.Label>
                            <Form.Control type="text" placeholder="Last name" />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" placeholder="Email" />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control type="number" placeholder="Phone Number" />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Social Links</Form.Label>
                        <Form.Control type="text" placeholder="Social links" />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Most active platform</Form.Label>
                        <Form.Control type="text" placeholder="Most active platform" />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Are you actively posting videos as an TikTok shop affiliate? </Form.Label>
                        <Form.Control type="text" placeholder="Yes/No" />
                      </Form.Group>
                      <Form.Group className="mb-3 position-relative" controlId="formBasicEmail">
                        <Form.Label>How many brands are you looking to work with monthly?</Form.Label>
                        <Form.Select>
                          <option>00</option>
                          <option>01</option>
                          <option>02</option>
                          <option>03</option>
                          <option>04</option>
                          <option>05</option>
                          <option>06</option>
                          <option>07</option>
                        </Form.Select>
                        <img src={require('../assets/images/dropdown.svg').default} alt="Logo || Brandfluence" className="eye-icon" />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>What are your preferered 3 niches/categories of content to create for brands?</Form.Label>
                        <Form.Control type="text" placeholder="Yes/No" />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Rough estimate of affiliate GMV in the last 30 days?</Form.Label>
                        <Form.Control type="text" placeholder="Yes/No" />
                      </Form.Group>
                      <Button variant="primary" type="submit" className="submit-btn">
                        Submit
                      </Button>
                    </Form> */}

                    <CreatorForm setShowLoader={setShowLoader}/>
                  </Tab>
                  <Tab eventKey="brand" title="As Brand">
                    {/* <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Brand Name</Form.Label>
                        <Form.Control type="email" placeholder="Brand Name" />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" placeholder="Email" />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control type="number" placeholder="Phone Number" />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Website Links</Form.Label>
                        <Form.Control type="text" placeholder="Social links" />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Social Links</Form.Label>
                        <Form.Control type="text" placeholder="Social links" />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Is your brands currently on Tik Tok Shop?</Form.Label>
                        <Form.Control type="text" placeholder="Yes/No" />
                      </Form.Group>
                      <Form.Group className="mb-3 position-relative" controlId="formBasicEmail">
                        <Form.Label>How many creators are you looking to work with monthly?</Form.Label>
                        <Form.Select>
                          <option>00</option>
                          <option>01</option>
                          <option>02</option>
                          <option>03</option>
                          <option>04</option>
                          <option>05</option>
                          <option>06</option>
                          <option>07</option>
                        </Form.Select>
                        <img src={require('../assets/images/dropdown.svg').default} alt="Logo || Brandfluence" className="eye-icon" />
                      </Form.Group>
                      <Form.Group className="mb-3 position-relative" controlId="formBasicEmail">
                        <Form.Label>How much affiliate GMV are you looking to generate per month?</Form.Label>
                        <Form.Select>
                          <option>00</option>
                          <option>01</option>
                          <option>02</option>
                          <option>03</option>
                          <option>04</option>
                          <option>05</option>
                          <option>06</option>
                          <option>07</option>
                        </Form.Select>
                        <img src={require('../assets/images/dropdown.svg').default} alt="Logo || Brandfluence" className="eye-icon" />
                      </Form.Group>
                      <Button variant="primary" type="submit" className="submit-btn">
                        Submit
                      </Button>
                    </Form> */}

                    <BrandFrom setShowLoader={setShowLoader}/>
                  </Tab>
                </Tabs>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Launch page end */}
    </>
  );
};
export default LaunchForm;
