import React, { useRef, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import { apiService } from "../services/apiservices";
import Multiselect from "multiselect-react-dropdown";

function RegisterForm({
  roleType,
  setShowLoader,
}) {
  const workMonthlyOptions = Array.from({ length: 11 }, (_, i) => i);
  const [showPassword, setShowPassword] = useState(false);
  const [profileImage, setProfileImage] = useState("")
  const [creatorData, setCreatorData] = useState({
    image: "",
    firstName: "",
    lastName: "",
    brandName: "",
    websiteLinks: "",
    email: "",
    phoneNumber: "",
    password: "",
    role: roleType,
    preferredNiches: [],
    socialLinks: [""],
    mostActivePlatform: "",
    tikTokShopAffiliate: "no",
    workMonthly: 0,
    isWeb: true,
  });
  const [errors, setErrors] = useState({
    socialLinks: [],
  });
  const [options, setOptions] = useState([
    { name: "Home Supplies", id: 1 },
    { name: "Kitchenware", id: 2 },
    { name: "Household Appliances", id: 3 },
    { name: "Womenswears", id: 4 },
    { name: "Menswear", id: 5 },
    { name: "Beauty & Personal Care", id: 6 },
    { name: "Electronics", id: 7 },
    { name: "Pet Supplies", id: 8 },
    { name: "Sports & Outdoors", id: 9 },
    { name: "Toys & Hobbies", id: 10 },
    { name: "Furniture", id: 11 },
    { name: "Health", id: 12 },
    { name: "Automotive", id: 13 },
    { name: "Fashion", id: 14 },
    { name: "Bags", id: 15 },
    { name: "Jewelry", id: 16 },
    { name: "Food & Beverage", id: 17 },
  ]);
  const imageRef = useRef()

  const handleChangeSocialLink = (index, value) => {
    const updatedLinks = [...creatorData.socialLinks];
    updatedLinks[index] = value;
    setCreatorData({ ...creatorData, socialLinks: updatedLinks });

    const updatedErrors = [...errors.socialLinks];
    const urlRegex = /^(https?:\/\/)(localhost|\w+(\.\w+)+)(:\d+)?(\/.*)?$/;
    if (value && !urlRegex.test(value)) {
      updatedErrors[index] = "Please enter a valid URL.";
    } else {
      updatedErrors[index] = "";
    }
    setErrors({ ...errors, socialLinks: updatedErrors });
  };

  const addSocialLink = () => {
    const lastSocialField = creatorData.socialLinks.length - 1
    console.log(creatorData.socialLinks[lastSocialField])
    if(creatorData.socialLinks[lastSocialField]){
      setCreatorData({
        ...creatorData,
        socialLinks: [...creatorData.socialLinks, ""],
      });
    }
    
    setErrors({ ...errors, socialLinks: [...errors.socialLinks, ""] });
  };

  const removeSocialLink = (index) => {
    const updatedSocialLinks = [...creatorData.socialLinks];
    updatedSocialLinks.splice(index, 1);

    const updatedErrors = { ...errors };
    updatedErrors.socialLinks.splice(index, 1);

    setCreatorData({ ...creatorData, socialLinks: updatedSocialLinks });
    setErrors(updatedErrors);
  };

  const validateInput = (name, value) => {
    let error = "";

    switch (name) {
      case "image":
        if(!value){
          error = "Profile image is required.";
        }
        break;
      case "firstName":
      case "lastName":
        if (!value.trim()) {
          error = `${name === "firstName" ? "First name" : "Last name"
            } is required.`;
        } else if (!/^[A-Za-z\s]+$/.test(value)) {
          error = `${name === "firstName" ? "First name" : "Last name"
            } must contain only letters and spaces.`;
        } else if (value.length < 2 || value.length > 50) {
          error = `${name === "firstName" ? "First name" : "Last name"
            } must be between 2 and 50 characters.`;
        }
        if (roleType == "brand") {
          error = ""
        }
        break;
        case "brandName":
          if (!value.trim()) {
            error = `Brand name is required`;
          } else if (value.length < 2 || value.length > 50) {
            error = `Brand name must be between 2 and 50 characters.`;
          }
          if (roleType == "creator") {
            error = ""
          }
          break;
      case "email":
        if (!value.trim()) {
          error = "Email is required.";
        } else if (
          !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/.test(value)
        ) {
          error = "Invalid email format.";
        }
        break;
      case "phoneNumber":
        if (value && !/^\d{10}$/.test(value)) {
          error = "Phone number must be 10 digits.";
        }
        break;
      case "password":
        const trimmedValue = value.trim();
        if (!trimmedValue) {
          error = "Password is required.";
        } else if (trimmedValue.length < 8) {
          error = "Password must be at least 8 characters long.";
        } else if (
          !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[`~!@#$%^&*()\-_+={[}\|:;"'<,>.?/]).*$/.test(
            trimmedValue
          )
        ) {
          error =
            "Password must include at least 1 letter, 1 number, and 1 special character.";
        }
        break;
        case "websiteLinks":
        const urlRegex = /^(https?:\/\/)(localhost|\w+(\.\w+)+)(:\d+)?(\/.*)?$/;
        if (!value.trim()) {
          error = "Website links is required.";
        } else if (value && !urlRegex.test(value)) {
          error = "Please enter a valid URL.";
        }
        if (roleType == "creator") {
          error = ""
        }
        break;
      case "mostActivePlatform":
        if (!value.trim()) {
          error = "Most active platform is required.";
        } else if (value.length < 3 || value.length > 50) {
          error = "Most active platform must be between 3 and 50 characters.";
        }
        break;
      default:
        break;
    }

    return error;
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCreatorData((prev) => ({ ...prev, [name]: value }));
    const error = validateInput(name, value);
    setErrors({ ...errors, [name]: error });
  };

  const handleFileChange = (event) => {
    const { name, files } = event.target;
    const file = files[0];

    if(file){
      
      const imageUrl = URL.createObjectURL(file)
      setProfileImage(imageUrl)
    }else{
      setProfileImage("")
    }
    

    setCreatorData((prev) => ({ ...prev, [name]: file }));

    const error = validateInput(name, file);

    setErrors((prev) => ({ ...prev, [name]: error }));
  };

  const handleMultiSelectChange = (selectedList, selectedItem) => {
    setCreatorData({ ...creatorData, preferredNiches: selectedList });
  };

  const onRemove = (selectedList, removedItem) => {
    setCreatorData({ ...creatorData, preferredNiches: selectedList });
  };

  const handleSubmit = async (e) => {
    console.log("submission started", creatorData)
    e.preventDefault();
    const newErrors = {};
    Object.keys(creatorData).forEach((key) => {
      newErrors[key] = validateInput(key, creatorData[key]);
    });

    setErrors(newErrors);
    console.log(errors)

    if (Object.values(newErrors).every((error) => error === "")) {
      setShowLoader(true);
      const form = new FormData();
      form.append("image", creatorData.image);
      if (roleType == "creator") {
        form.append("firstName", creatorData.firstName);
        form.append("lastName", creatorData.lastName);
      } else if (roleType == "brand") {
        form.append("brandName", creatorData.brandName)
        form.append("websiteLinks",creatorData.websiteLinks)
      }
      form.append("email", creatorData.email);
      form.append("phoneNumber", creatorData.phoneNumber);
      form.append("password", creatorData.password);
      form.append("activePlatform", creatorData.mostActivePlatform);
      creatorData.preferredNiches.map((category,index)=>{
        form.append("preferredNiches",category.name)
      })
      creatorData.socialLinks.map((link,index)=>{
        if(link.trim() !== ""){
          form.append("socialLinks", link)
        }
      })
      form.append(
        "isActiveTikTokAffiliate",
        creatorData.tikTokShopAffiliate == "yes" ? true : false
      );
      form.append("monthlyBrandGoal", creatorData.workMonthly);
      form.append("role", creatorData.role);
      form.append("isWeb", creatorData.isWeb);
      try {
        // for (let [key, value] of form.entries()) {
        //   console.log(key, value); // Logs each key-value pair in the FormData
        // }
        const response = await apiService.userRegister(form);
        // const response = { status: 201 };
        console.log("response", response);

        if (response.status == 201) {
          setCreatorData({
            image: "",
            firstName: "",
            lastName: "",
            brandName : "",
            websiteLinks: "",
            email: "",
            phoneNumber: "",
            password: "",
            preferredNiches: [],
            role: roleType,
            socialLinks: [""],
            mostActivePlatform: "",
            tikTokShopAffiliate: "no",
            workMonthly: 0,
            isWeb: true,
          });
          setProfileImage("")
          imageRef.current.value = ""
          Swal.fire({
            icon: "success",
            title: "Registration Successful",
            text: response?.message || "You have successfully registered!",
          });
          setShowLoader(false);
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text:
          error?.response?.data?.message ||
            "Something went wrong, please try again.",
        });
        setShowLoader(false);
      }
    }
  };
  return (
    <div className="right-side-form">
      <h2 className="register-text">Register Now!</h2>
      <Form>
        <Row>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Upload Your Profile</Form.Label>
              <div className="upload-img">
                <img src={profileImage? profileImage : require("../assets/images/upload-icon.png")} />
                <Form.Control
                  type="file"
                  placeholder="Upload Image"
                  name="image"
                  onChange={handleFileChange}
                  ref={imageRef}
                />
              </div>
              {errors.image && (
                <span className="text-danger">{errors.image}</span>
              )}
            </Form.Group>
          </Col>
        </Row>
        {
          roleType == "creator" ? (<Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>First name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="First Name"
                  name="firstName"
                  value={creatorData.firstName}
                  onChange={handleInputChange}
                />
                {errors.firstName && (
                  <span className="text-danger">{errors.firstName}</span>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Last name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Last name"
                  name="lastName"
                  value={creatorData.lastName}
                  onChange={handleInputChange}
                />
                {errors.lastName && (
                  <span className="text-danger">{errors.lastName}</span>
                )}
              </Form.Group>
            </Col>
          </Row>) : (<Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Brand Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Brand Name"
              name="brandName"
              value={creatorData.brandName}
              onChange={handleInputChange}
            />
            {errors.brandName && (
              <span className="text-danger">{errors.brandName}</span>
            )}
          </Form.Group>)
        }

        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="Email"
            name="email"
            value={creatorData.email}
            onChange={handleInputChange}
          />
          {errors.email && <span className="text-danger">{errors.email}</span>}
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Phone Number</Form.Label>
          <Form.Control
            type="number"
            placeholder="Phone Number"
            name="phoneNumber"
            value={creatorData.phoneNumber}
            onChange={handleInputChange}
          />
          {errors.phoneNumber && (
            <span className="text-danger">{errors.phoneNumber}</span>
          )}
        </Form.Group>
        <Form.Group
          className="mb-3 position-relative"
          controlId="formBasicPassword"
        >
          <Form.Label>Password</Form.Label>
          <Form.Control
            type={showPassword ? "text" : "password"}
            placeholder="Enter your password"
            name="password"
            value={creatorData.password}
            onChange={handleInputChange}
          />
          {errors.password && (
            <span className="text-danger">{errors.password}</span>
          )}
          <img
            onClick={() => {
              setShowPassword((prev) => !prev);
            }}
            src={
              showPassword
                ? require("../assets/images/view-eye.svg").default
                : require("../assets/images/eye-open.svg").default
            }
            alt="Logo || Brandfluence"
            className="eye-icon"
          />
        </Form.Group>
        {
          roleType == "brand" &&
          <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Website Links</Form.Label>
          <Form.Control
            type="text"
            placeholder="Website links"
            name="websiteLinks"
            value={creatorData.websiteLink}
            onChange={handleInputChange}
          />
          {errors.websiteLinks && (
            <span className="text-danger">{errors.websiteLinks}</span>
          )}
        </Form.Group>
        }
        <Form.Group className="mb-1" controlId="formBasicEmail">
          <Form.Label>Social Links</Form.Label>
          <div className={creatorData.socialLinks.length > 2 ? "outer-div" : ""}>
          {creatorData.socialLinks.map((link, index) => (
            <div key={index} className="mb-2 trash-area">
              <Form.Control
                type="text"
                placeholder={`Social Link ${index + 1}`}
                value={link}
                onChange={(e) => handleChangeSocialLink(index, e.target.value)}
              />
              {index != 0 ? (
                <i
                  className="fa fa-trash align-middle"
                  aria-hidden="true"
                  onClick={() => removeSocialLink(index)}
                ></i>
              ) : (
                ""
              )}

              {errors.socialLinks[index] && (
                <span className="text-danger">{errors.socialLinks[index]}</span>
              )}
            </div>
          ))}
          </div>
          <Button variant="link" onClick={addSocialLink} className="add-btn">
            + Add More
          </Button>
        </Form.Group>
        <Form.Group
          className="mb-3 position-relative"
          controlId="formBasicEmail"
        >
          <Form.Label>Category</Form.Label>
          <Multiselect
            className="multiselector-area"
            placeholder={creatorData.preferredNiches.length >= 1 ? "" : "Select"}
            options={options}
            selectedValues={creatorData.preferredNiches}
            onSelect={handleMultiSelectChange}
            onRemove={onRemove}
            displayValue="name"
          />
          <img
            src={require("../assets/images/dropdown.svg").default}
            alt="Logo || Brandfluence"
            className="eye-icon"
          />
          {errors.preferredNiches && (
            <span className="text-danger">{errors.preferredNiches}</span>
          )}
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Most active platform</Form.Label>
          <Form.Control
            type="text"
            placeholder="Most active platform"
            name="mostActivePlatform"
            value={creatorData.mostActivePlatform}
            onChange={handleInputChange}
          />
          {errors.mostActivePlatform && (
            <span className="text-danger">{errors.mostActivePlatform}</span>
          )}
        </Form.Group>
        <Form.Group
          className="mb-3 position-relative"
          controlId="formBasicEmail"
        >
          <Form.Label>
            Are you actively posting videos as an TikTok shop affiliate?{" "}
          </Form.Label>
          <Form.Select
            name="tikTokShopAffiliate"
            value={creatorData.tikTokShopAffiliate}
            onChange={handleInputChange}
          >
            <option value={"yes"}>Yes</option>
            <option value={"no"}>No</option>
          </Form.Select>
          <img
            src={require("../assets/images/dropdown.svg").default}
            alt="Logo || Brandfluence"
            className="eye-icon"
          />
        </Form.Group>
        <Form.Group
          className="mb-3 position-relative"
          controlId="formBasicEmail"
        >
          <Form.Label>
            How many brands are you looking to work with monthly?
          </Form.Label>
          <Form.Select
            name="workMonthly"
            value={creatorData.workMonthly}
            onChange={handleInputChange}
          >
            {workMonthlyOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Form.Select>
          <img
            src={require("../assets/images/dropdown.svg").default}
            alt="Logo || Brandfluence"
            className="eye-icon"
          />
        </Form.Group>
        {roleType == "brand" && (
          <Form.Group
            className="mb-3 position-relative"
            controlId="formBasicEmail"
          >
            <Form.Label>
              How many affiliates / creators are you looking to get
            </Form.Label>
            <Form.Select
              name="workMonthly"
              value={creatorData.workMonthly}
              onChange={handleInputChange}
            >
              {workMonthlyOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Form.Select>
            <img
              src={require("../assets/images/dropdown.svg").default}
              alt="Logo || Brandfluence"
              className="eye-icon"
            />
          </Form.Group>
        )}
        {roleType == "brand" && (
          <Form.Group
            className="mb-3 position-relative"
            controlId="formBasicEmail"
          >
            <Form.Label>
              Do you have a affiliate program setup on Instagram or TikTok ?
            </Form.Label>
            <Form.Select
              name="tikTokShopAffiliate"
              value={creatorData.tikTokShopAffiliate}
              onChange={handleInputChange}
            >
              <option value={"yes"}>Yes</option>
              <option value={"no"}>No</option>
            </Form.Select>
            <img
              src={require("../assets/images/dropdown.svg").default}
              alt="Logo || Brandfluence"
              className="eye-icon"
            />
          </Form.Group>
        )}

        <Button variant="primary" onClick={handleSubmit} className="submit-btn">
          Submit
        </Button>
      </Form>
    </div>
  );
}

export default RegisterForm;

