import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "../component/Login";
import CommonLayout from "../common/CommonLayout";
import ForgotPassword from "../component/ForgotPassword";
import GeneratePassword from "../component/GeneratePassword";
import Register from "../component/Register";
import Home from "../component/Home";
import Creator from "../component/Creator";
import Brands from "../component/Brands";
import LaunchForm from "../component/LaunchForm";
import Marketplace from "../component/Marketplace";
import CreatorProfile from "../component/CreaterProfile";

const Routing = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/home"
          element={<CommonLayout>{<Home />}</CommonLayout>}
        ></Route>
         <Route
          path="/creator"
          element={<CommonLayout>{<Creator />}</CommonLayout>}
        ></Route>
         <Route
          path="/brands"
          element={<CommonLayout>{<Brands />}</CommonLayout>}
        ></Route>
         <Route
          path="/marketplace"
          element={<CommonLayout>{<Marketplace />}</CommonLayout>}
        ></Route>
            <Route
          path="/creater-profile"
          element={<CommonLayout>{<CreatorProfile />}</CommonLayout>}
        ></Route>
        <Route
          path="/login"
          element={<Login />}
        ></Route>
        <Route
          path="/forgot-password"
          element={<ForgotPassword />}
        ></Route>
        <Route
          path="/generate-password"
          element={<GeneratePassword />}
        ></Route>
         <Route
          path="/register"
          element={<Register />}
        ></Route>
         <Route
          // path="/launch-details"
          path="/"

          element={<LaunchForm />}
        ></Route>
      </Routes>
    </Router>
  );
};

export default Routing;
