import React from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { config } from '../config/config'

function MarketPlaceDataDetailModal({show,handleClose,data}) {
    console.log(data)
  return (
    <Modal show={show} onHide={handleClose} centered size="lg" className="product-modal">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className="product-detail-top">
                    <img src={data.image? `${config.imageUrl}${data.image}` : require("../assets/images/qb-image.png")} />
                        <div className="product-detail">
                            <h5>{data?.brandName? data.brandName : `${data.firstName} ${data.lastName}`}</h5>
                            <p><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M14.166 2.91602H5.83268C3.33268 2.91602 1.66602 4.16602 1.66602 7.08268V12.916C1.66602 15.8327 3.33268 17.0827 5.83268 17.0827H14.166C16.666 17.0827 18.3327 15.8327 18.3327 12.916V7.08268C18.3327 4.16602 16.666 2.91602 14.166 2.91602ZM14.5577 7.99102L11.9493 10.0743C11.3993 10.516 10.6993 10.7327 9.99935 10.7327C9.29935 10.7327 8.59102 10.516 8.04935 10.0743L5.44102 7.99102C5.17435 7.77435 5.13268 7.37435 5.34102 7.10768C5.55768 6.84102 5.94935 6.79102 6.21602 7.00768L8.82435 9.09102C9.45768 9.59935 10.5327 9.59935 11.166 9.09102L13.7743 7.00768C14.041 6.79102 14.441 6.83268 14.6493 7.10768C14.866 7.37435 14.8243 7.77435 14.5577 7.99102Z" fill="#1037FD" />
                            </svg>Email: {data.email}</p>
                            <p><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M6.03464 1.66602H4.4513C2.6263 1.66602 1.66797 2.62435 1.66797 4.44102V6.02435C1.66797 7.84102 2.6263 8.79935 4.44297 8.79935H6.0263C7.84297 8.79935 8.8013 7.84102 8.8013 6.02435V4.44102C8.80964 2.62435 7.8513 1.66602 6.03464 1.66602Z" fill="#1037FD" />
                                <path d="M15.5576 1.66602H13.9742C12.1576 1.66602 11.1992 2.62435 11.1992 4.44102V6.02435C11.1992 7.84102 12.1576 8.79935 13.9742 8.79935H15.5576C17.3742 8.79935 18.3326 7.84102 18.3326 6.02435V4.44102C18.3326 2.62435 17.3742 1.66602 15.5576 1.66602Z" fill="#1037FD" />
                                <path d="M15.5576 11.1914H13.9742C12.1576 11.1914 11.1992 12.1497 11.1992 13.9664V15.5497C11.1992 17.3664 12.1576 18.3247 13.9742 18.3247H15.5576C17.3742 18.3247 18.3326 17.3664 18.3326 15.5497V13.9664C18.3326 12.1497 17.3742 11.1914 15.5576 11.1914Z" fill="#1037FD" />
                                <path d="M6.03464 11.1914H4.4513C2.6263 11.1914 1.66797 12.1497 1.66797 13.9664V15.5497C1.66797 17.3747 2.6263 18.3331 4.44297 18.3331H6.0263C7.84297 18.3331 8.8013 17.3747 8.8013 15.5581V13.9747C8.80964 12.1497 7.8513 11.1914 6.03464 11.1914Z" fill="#1037FD" />
                            </svg>Health and Wellness products</p>
                            <p><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M15 1.66699H5C3.61667 1.66699 2.5 2.77533 2.5 4.14199V13.2337C2.5 14.6003 3.61667 15.7087 5 15.7087H5.63333C6.3 15.7087 6.93333 15.967 7.4 16.4337L8.825 17.842C9.475 18.4837 10.5333 18.4837 11.1833 17.842L12.6083 16.4337C13.075 15.967 13.7167 15.7087 14.375 15.7087H15C16.3833 15.7087 17.5 14.6003 17.5 13.2337V4.14199C17.5 2.77533 16.3833 1.66699 15 1.66699ZM10 4.79199C11.075 4.79199 11.9417 5.65866 11.9417 6.73366C11.9417 7.80866 11.075 8.67533 10 8.67533C8.925 8.67533 8.05833 7.80033 8.05833 6.73366C8.05833 5.65866 8.925 4.79199 10 4.79199ZM12.2333 12.5503H7.76667C7.09167 12.5503 6.7 11.8003 7.075 11.242C7.64167 10.4003 8.74167 9.83366 10 9.83366C11.2583 9.83366 12.3583 10.4003 12.925 11.242C13.3 11.8003 12.9 12.5503 12.2333 12.5503Z" fill="#1037FD" />
                            </svg>Followers: 9.9K </p>
                        </div>
                    </div>
                    <Row className="product-info">
                        <Col md={4}>
                            <div className="product-info-inner border-remove">
                                <h3>$897.82</h3>
                                <p>GMV</p>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="product-info-inner">
                                <h3>63</h3>
                                <p>Items Sold</p>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="product-info-inner">
                                <h3>1.2k</h3>
                                <p>Avg. VIEWS</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="product-images-bottom">
                        <Col md={4}>
                           <div className="product-images-bottom">
                                <img src={require("../assets/images/product1.png")} />
                           </div>
                        </Col>
                        <Col md={4}>
                           <div className="product-images-bottom">
                                <img src={require("../assets/images/product2.png")} />
                           </div>
                        </Col>
                        <Col md={4}>
                           <div className="product-images-bottom">
                                <img src={require("../assets/images/product3.png")} />
                           </div>
                        </Col>
                    </Row>
                </Modal.Body>
            
            </Modal>
  )
}

export default MarketPlaceDataDetailModal